import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "authorization"
    }}>{`Authorization`}</h1>
    <p>{`Getting access to Planday’s API is managed by the standard OAuth2 protocol using the Authorization Code flow.
To make calls to the Planday API, you first need to create an API application in Planday to hold the required credentials. The application must be authorized for each Planday portal on which the integration will access data.`}</p>
    <h4 {...{
      "id": "customer-or-partner"
    }}>{`Customer or Partner?`}</h4>
    <p>{` The authorisation flow depends on which type of integration you are building. Select the right approach and continue reading how your integration should manage the authorisation.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Customer`}</strong>{`: I’m a Planday customer or consultant for a Planday customer and I want to build an integration to one or more of my Planday portals.`}</li>
      <li parentName="ul"><strong parentName="li">{`Partner`}</strong>{`: I want to build an awesome integration between my system and Planday that will work for multiple customers.`}</li>
    </ul>
    <h2 {...{
      "id": "authorization-flow-for-customers"
    }}>{`Authorization flow for customers`}</h2>
    <h3 {...{
      "id": "create-an-application"
    }}>{`Create an application`}</h3>
    <p>{`This guide walks you through how to get started with managing the authorization of your new integration to Planday and how to access data in your Planday portal through the API. You need to be logged in as an Administrator and you need to have the necessary rights to allow access to the data that the integration will want to utilize. `}</p>
    <p>{`First you need to create your Planday API application, that enables access the API resources in Planday. Login to Planday and follow the steps below.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong>{`
Navigate to Settings > Integrations > API Access. You need to be an Administrator to access this page.
`}<img alt="API access" src={require("./images/api_access.png")} />{`
`}<strong parentName="p">{`Step 2`}</strong>{`
From this page you can retrieve the Client ID and Refresh Token required to build an integration, and access Planday resources. To start:`}</p>
    <ol>
      <li parentName="ol">{`Select Create App at the top right of the page.`}</li>
      <li parentName="ol">{`Next, define the resources or actions that should be accessible to the application under the section “Scopes”. For example, you can create an application which can read schedule data.`}</li>
      <li parentName="ol">{`Enter a short name for the application. This name will be visible to you as well as any customers, during authorization.`}</li>
      <li parentName="ol">{`Select Save.`}</li>
    </ol>
    <p><img alt="Create application" src={require("./images/create_application.png")} />{`
`}<strong parentName="p">{`Step 3`}</strong>{`
Now your application will be listed on the API Access page. To access the resources for the application, you must authorize the application and generate a Refresh Token. To do so, select Authorize next to the name of the application.
`}<img alt="Authorize app" src={require("./images/authorize_app.png")} /></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`
You will be redirected to a consent screen where you need to confirm that the application is allowed to access the listed resources from your Planday portal.
Make sure you read through the entire list and that you agree to allow this application access to all data listed (If the list is long, you’ll need to scroll down!). The application may gain access to all the data listed here, for all Planday users you administer, which you yourself have access to as an Administrator in Planday.
For example, if you have edit rights to all employees’ pay rates and you’re authorizing the application to “Edit pay rates”, the integration will be able to update pay rates for all employees.
Select Authorize.`}</p>
    <p><img alt="Approve app" src={require("./images/approve_app.png")} /></p>
    <p>{`At any time, you can pause or stop the application from accessing the specified resources in Planday by selecting the Revoke button next to the name of the application. Once the application is created, it is also possible to rename or delete it permanently from the API Access page.`}</p>
    <h3 {...{
      "id": "connect-an-existing-api-application-on-other-planday-portals"
    }}>{`Connect an existing API application on other Planday portals`}</h3>
    <p>{`An application can be connected to multiple Planday portals. This is useful in cases where an integration is to be used in several different Planday portals that you administer.
To enable the application in a different Planday portal, perform the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Open the Planday portal that needs access to the integration and navigate to the API access page (Settings > Integrations > API Access). `}</li>
      <li parentName="ol">{`Click the “Connect App” button in the top right of the page.`}</li>
      <li parentName="ol">{`Enter the App ID of the application which already exists. You can obtain it by going to the API access page in the portal where the application is created, hovering over the hidden App ID field, and then selecting “Copy”.`}</li>
      <li parentName="ol">{`Click the “Save” button. The application should now be listed on the API access page.`}</li>
      <li parentName="ol">{`Authorize the application to retrieve the (Refresh) Token. To do so, select Authorize next to the name of the application.`}</li>
      <li parentName="ol">{`You will be redirected to the consent screen where you need to confirm that the application is allowed to access the listed resources. Select Authorize.`}</li>
    </ol>
    <p>{`Repeat the five steps above on the Planday portals you want to connect the integration to. There needs to be an unique (Refresh) Token from each of the portals on which the integration will be used.`}</p>
    <h3 {...{
      "id": "exchanging-the-refresh-token-for-an-access-token"
    }}>{`Exchanging the Refresh Token for an Access Token`}</h3>
    <p>{`To access the resources defined by an application you’ll need the following:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`CLIENT_ID`}</em>{` – this can be found in the “App Id” column on the API Access page`}</li>
      <li parentName="ul"><em parentName="li">{`REFRESH TOKEN`}</em>{` – this can be found in the “Token” column on the API Access page. The application has to be authorized.`}</li>
    </ul>
    <p>{`Before you can make calls to the API resources, you first need to obtain an Access Token by exchanging the Refresh Token from the previous sections for an Access Token. To do this, you need to make a POST request using any tool you want that can make HTTP calls (curl, postman, etc.) The request is as follows (replace CLIENT_ID and TOKEN with appropriate values):`}</p>
    <p><strong parentName="p">{`POST`}</strong>{` `}<a parentName="p" {...{
        "href": "https://id.planday.com/connect/token"
      }}><inlineCode parentName="a">{`https://id.planday.com/connect/token`}</inlineCode></a>{`
`}<strong parentName="p">{`Request Headers:`}</strong>{`
`}<inlineCode parentName="p">{`Content-Type: application/x-www-form-urlencoded`}</inlineCode>{`
`}<strong parentName="p">{`Request Body:`}</strong>{`
`}<inlineCode parentName="p">{`client_id=CLIENT_ID&grant_type=refresh_token&refresh_token=TOKEN`}</inlineCode></p>
    <hr></hr>
    <p><strong parentName="p">{`CURL:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST \\
--data "client_id=CLIENT_ID&grant_type=refresh_token&refresh_token=TOKEN" \\
https://id.planday.com/connect/token
`}</code></pre>
    <p>{`If the request is successful, the response will contain the JSON object with the “access_token” field. This is the ACCESS_TOKEN which can be used to make calls to the API.`}</p>
    <h3 {...{
      "id": "accessing-api-resources"
    }}>{`Accessing API resources`}</h3>
    <p>{`Each request to the Plandays API must contain two headers:`}</p>
    <p><inlineCode parentName="p">{`X-ClientId: CLIENT_ID
Authorization: Bearer ACCESS_TOKEN`}</inlineCode></p>
    <p>{`You can read more about how to obtain the CLIENT_ID and ACCESS_TOKEN in the `}<strong parentName="p">{`Exchanging the Refresh Token for an Access Token`}</strong>{` section above.
On `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/"
      }}>{`https://openapi.planday.com/`}</a>{` you can find all the information you need to make a call to any Planday Open API endpoint (URL, HTTP Method, Body etc.). `}</p>
    <p>{`Here is a sample request, which fetches all departments from the Planday portal the application is defined in:`}</p>
    <p><strong parentName="p">{`GET`}</strong>{` `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/hr/v1/Departments"
      }}><inlineCode parentName="a">{`https://openapi.planday.com/hr/v1/Departments`}</inlineCode></a>{`
`}<strong parentName="p">{`Request headers:`}</strong>{`
`}<inlineCode parentName="p">{`X-ClientId: CLIENT_ID
 Authorization: Bearer ACCESS_TOKEN`}</inlineCode></p>
    <hr></hr>
    <p><strong parentName="p">{`CURL:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`curl -X GET \\
-H "Authorization: Bearer ACCESS_TOKEN" \\
-H "X-ClientId: CLIENT_ID" \\
https://openapi.planday.com/hr/v1/Departments
`}</code></pre>
    <h2 {...{
      "id": "authorization-flow-for-product-partners"
    }}>{`Authorization flow for Product Partners`}</h2>
    <p>{`Are you a Product Partner implementing an integration that will be available for multiple mutual customers, please read through this article and learn how to manage the authorization flow that enables access to Planday.`}</p>
    <p>{`The authorization of your integration follows an OAuth2 flow with a bearer token. You have to create and API application inside your Planday demo portal, which will be authorized on each customers Planday portal where the integration will access data.
To gain access to a Planday demo account please contact Planday API support on `}<a parentName="p" {...{
        "href": "mailto:apisupport@planday.com"
      }}>{`apisupport@planday.com`}</a>{`.`}</p>
    <h3 {...{
      "id": "create-an-application-1"
    }}>{`Create an application`}</h3>
    <p>{`To create an application you need to be logged in as an Administrator on your Planday demo portal, and you need to have the necessary rights to allow access to the data that the integration will utilize.
Start by logging in to Planday and follow the steps below.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong>{`
Navigate to Settings > Integrations > API Access. You need to be an Administrator to access this page.
`}<img alt="API access" src={require("./images/api_access.png")} />{`
`}<strong parentName="p">{`Step 2`}</strong>{`
From this page you can retrieve the Client ID and Refresh Token required to build an integration, and access Planday resources. To start:`}</p>
    <ol>
      <li parentName="ol">{`Select Create App at the top right of the page.`}</li>
      <li parentName="ol">{`Next, define the resources or actions that should be accessible to the application under the section “Scopes”. For example, you can create an application which can read schedule data.`}</li>
      <li parentName="ol">{`Enter a short name for the application. This name will be visible to you as well as any customers, during authorization.`}</li>
      <li parentName="ol">{`Select Save.`}</li>
      <li parentName="ol">{`Congrats, you have now created you application.`}</li>
    </ol>
    <p><img alt="Create application" src={require("./images/create_application.png")} />{`
`}<strong parentName="p">{`Step 3`}</strong>{`
Your application will be listed on the API Access page. To access the resources for the application on your demo portal, you must authorize the application and generate a Refresh Token. To do so, select Authorize next to the name of the application.
`}<img alt="Authorize app" src={require("./images/authorize_app.png")} /></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`
You will be redirected to a consent screen where you need to confirm that the application is allowed to access the listed resources from your Planday portal. This is the also how the consent screen will look like when your customers authorizes the integration through the authorization code flow described in the next section `}<strong parentName="p">{`Authorization code flow.`}</strong>{` Now Select Authorize.`}</p>
    <p><img alt="Approve app" src={require("./images/approve_app.png")} /></p>
    <p>{`At any time, you can pause or stop the application from accessing the specified resources in your Planday demo account by selecting the Revoke button next to the name of the application. Once the application is created, it is also possible to rename or delete it permanently from the API Access page.`}</p>
    <h3 {...{
      "id": "authorization-code-flow"
    }}>{`Authorization code flow`}</h3>
    <p>{`To read how to manage the authorization code flow, please go to our guide. (`}<a parentName="p" {...{
        "href": "./authorization-flow"
      }}>{`Authorisation Code Flow article`}</a>{`)`}</p>
    <h3 {...{
      "id": "exchanging-the-refresh-token-for-an-access-token-1"
    }}>{`Exchanging the Refresh Token for an Access Token`}</h3>
    <p>{`To access the resources defined by an application you’ll need the following:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`CLIENT_ID`}</em>{` – this can be found in the “App Id” column on the API Access page`}</li>
      <li parentName="ul"><em parentName="li">{`REFRESH TOKEN`}</em>{` – this can be found in the “Token” column on the API Access page. The application has to be authorized.`}</li>
    </ul>
    <p>{`Before you can make calls to the API resources, you first need to obtain an Access Token by exchanging the Refresh Token from the previous sections for an Access Token. To do this, you need to make a POST request using any tool you want that can make HTTP calls (curl, postman, etc.) The request is as follows (replace CLIENT_ID and TOKEN with appropriate values):`}</p>
    <p><strong parentName="p">{`POST`}</strong>{` `}<a parentName="p" {...{
        "href": "https://id.planday.com/connect/token"
      }}><inlineCode parentName="a">{`https://id.planday.com/connect/token`}</inlineCode></a>{`
`}<strong parentName="p">{`Request Headers:`}</strong>{`
`}<inlineCode parentName="p">{`Content-Type: application/x-www-form-urlencoded`}</inlineCode>{`
`}<strong parentName="p">{`Request Body:`}</strong>{`
`}<inlineCode parentName="p">{`client_id=CLIENT_ID&grant_type=refresh_token&refresh_token=TOKEN`}</inlineCode></p>
    <hr></hr>
    <p><strong parentName="p">{`CURL:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST \\
--data "client_id=CLIENT_ID&grant_type=refresh_token&refresh_token=TOKEN" \\
https://id.planday.com/connect/token

`}</code></pre>
    <p>{`If the request is successful, the response will contain the JSON object with the “access_token” field. This is the ACCESS_TOKEN which can be used to make calls to the API.`}</p>
    <h3 {...{
      "id": "accessing-api-resources-1"
    }}>{`Accessing API resources`}</h3>
    <p>{`Each request to the Plandays API must contain two headers:`}</p>
    <p><strong parentName="p">{`X-ClientId:`}</strong>{` CLIENT_ID
`}<strong parentName="p">{`Authorization:`}</strong>{` Bearer ACCESS_TOKEN`}</p>
    <p>{`You can read more about how to obtain the CLIENT_ID and ACCESS_TOKEN in the `}<strong parentName="p">{`Exchanging the Refresh Token for an Access Token`}</strong>{` section above.
On `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/"
      }}>{`https://openapi.planday.com/`}</a>{` you can find all the information you need to make a call to any Planday Open API endpoint (URL, HTTP Method, Body etc.). Here is a sample request, which fetches all departments from the Planday portal the application is defined in:`}</p>
    <p><strong parentName="p">{`GET `}</strong><a parentName="p" {...{
        "href": "https://openapi.planday.com/hr/v1/Departments"
      }}><inlineCode parentName="a">{`https://openapi.planday.com/hr/v1/Departments`}</inlineCode></a>{`
`}<strong parentName="p">{`Request headers:`}</strong>{`
`}<inlineCode parentName="p">{`X-ClientId: CLIENT_ID
 Authorization: Bearer ACCESS_TOKEN`}</inlineCode></p>
    <hr></hr>
    <p><strong parentName="p">{`CURL:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`curl -X GET \\
-H "Authorization: Bearer ACCESS_TOKEN" \\
-H "X-ClientId: CLIENT_ID" \\
https://openapi.planday.com/hr/v1/Departments
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      